<template>
    <div class="empty">
        <slot />
    </div>
</template>

<script>
export default {
    name: "Empty"
}
</script>
